import singleton from '@/modules/i18n/singleton';
import toDate from '../helpers/toDate.js';

import { format, formatRelative, differenceInDays } from 'date-fns';
import { es, de, fr } from 'date-fns/locale';
const dateFnsLocales = { es, de, fr };

/*
Este mixin declara el metodo $t,
pero el plugin src\i18n\plugin.js declara Vue.prototype.$t

Vue le da prioridad al metodo $t de este mixin, pero lanza una advertencia en consola
*/

// Determinar si se esta usando ya un plugin que declare la funcion $t()
import Vue from "vue";
const translateFunctionName = typeof Vue.prototype.$t == "undefined" ? "$t" : "$translate";


function dmyToDate(ddmmyyyy) {
  if (ddmmyyyy.length != 8) {
    return null;
  }

  let day = ddmmyyyy.substr(0, 2);
  let month = parseInt(ddmmyyyy.substr(2, 2) - 1);
  let year = ddmmyyyy.substr(4);

  let date = new Date();
  date.setFullYear(year, month, day);
  date.setHours(0, 0, 0, 0);

  return date;
}

export default {
  props: {
    i18nLanguage: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      i18nLanguageProp: null
    };
  },

  watch: {
    i18nLanguage: {
      immediate: true,
      handler(newValue) {
        this.i18nLanguageProp = newValue;
      }
    }
  },

  computed: {
    $i18n() {
      return {
        language: this.i18nLanguageProp || singleton.language,
        setDefaultLanguage: singleton.setLanguage,
        setLanguage: this.setComponentLanguage
      };
    }
  },

  methods: {
    /*
    Translate the string in the current language*
    The current language is the language specified via the prop i18n-language.
    If the prop is absent, the property "language" of the global instance is used
    */
    [translateFunctionName](string, params, defaultValue = null) {
      let language = this.i18nLanguage || this.i18nLanguageProp || singleton.language;
      return singleton.getTranslation(language, string, params, defaultValue);
    },

    /*
    Format a unix TIMESTAMP
    */
    $ts(unixTimestamp, dateFormat) {
      if (!unixTimestamp) {
        return '';
      }

      let date = new Date(unixTimestamp * 1000);
      return this.$date(date, dateFormat);
    },

    /*
    Time range
    */
    $time(start, end = null) {
      let dateStart = new Date(start * 1000);
      let dateEnd = end ? new Date(end * 1000) : null;

      // !!! Machete.  El locale "es", que se usa en v3 como "Español(LA)", asume el estandar europeo (no pone am/pm)
      // Si el idioma es "es" asumir que el locale es "es-CO"
      let locale = this.$i18n.language == 'es' ? 'es-CO' : this.$i18n.language;

      let retval = dateStart.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }).replace(". m.", ".m.");
      if (dateEnd) {
        retval = retval + ' - ' + dateEnd.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }).replace(". m.", ".m.");
      }

      return retval;
    },

    /*
    Format a date in the current language*
    */
    $date(string, dateFormat) {
      if (!string) {
        return string;
      }

      // let date = new Date(string);
      let date = toDate(string);
      if (!date) {
        console.warn("$date: Fecha invalida", string);
        return null;
      }

      let dateFnsLocale = dateFnsLocales[this.$i18n.language];

      switch (dateFormat) {
        case "day":
          // 02/28/2004,  2.28.2004,
          return date.toLocaleDateString(this.$i18n.language);

        case "time":
          // 23:45,  11:45 p.m.
          return date.toLocaleTimeString(this.$i18n.language, { hour: '2-digit', minute: '2-digit' }).replace(". m.", ".m.");

        case "date":
          // 3 mar 2017, 13:41,   3 mar 2017, 1:41 p. m.,   3 Mär, 2017, 13:41

          // date-dns no tiene locale para es-CO (que usa am/pm), asi que
          // usaremos solo el formato de fecha ISO de date-fns ("PP") y lo concatenamos con
          // la hora generada por toLocaleTimeString (que SI maneja es-CO)
          // return format(date, "PPp", { locale: dateFnsLocale });
          return format(date, "PP", { locale: dateFnsLocale }) + ", " + this.$date(date, "time");

        case null:
        case undefined:
        case false: {
          let diff = Math.abs(differenceInDays(new Date(), date));
          return diff > 6 ?
            this.$date(date, "date").replace(' ' + (new Date()).getFullYear(), '').replace(",,", ",") // Hide the current year
            :
            formatRelative(date, new Date(), { locale: dateFnsLocale });
        }
        default:
          return format(date, dateFormat, { locale: dateFnsLocale });
      }
    },

    /*
    Format currency
    */
    $cr(value, currency = 'EUR') {
      if (currency && currency.toLowerCase() == 'eur') {
        // No funciona consistentemente entre navegadores el separador de miles/comas (!!!!)
        let str = Number(value).toLocaleString('US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return str
          .replaceAll(".", ",")         // Solo dejar comas   xxx,xxx,xxx,xxx,dd
          .replace(/,(?=[^,]*$)/, '.')  // cambiar la ultima coma por un punto
          + ' MXN';                     // agregar posfijo MXN
      }

      return Number(value).toLocaleString(this.$i18n.language, { style: 'currency', currency });
    },

    $cr(value, currency = 'MXN') {
      if (currency && currency.toLowerCase() == 'mxn') {
        // No funciona consistentemente entre navegadores el separador de miles/comas (!!!!)
        let str = Number(value).toLocaleString('US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return str
          .replaceAll(".", ",")         // Solo dejar comas   xxx,xxx,xxx,xxx,dd
          .replace(/,(?=[^,]*$)/, '.')  // cambiar la ultima coma por un punto
          + ' MXN';                     // agregar posfijo MXN
      }

      return Number(value).toLocaleString(this.$i18n.language, { style: 'currency', currency });
    },

    setComponentLanguage(language) {
      this.i18nLanguageProp = language;
    }
  },

  /*
  Load dictionary from component's "i18n" property
  */
  beforeCreate() {
    if (!this.$options.i18n) {
      return;
    }
    singleton.patch(this.$options.i18n);
  }
};